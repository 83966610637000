import React from "react"

const ShareIcon = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path
        d="M29,16c-0.55,0-1,0.45-1,1v11H4V17c0-0.55-0.45-1-1-1s-1,0.45-1,1v11v1v1h28v-1v-1V17C30,16.45,29.55,16,29,16z
	"
      />
      <path
        d="M21.63,9.24l-4.2-4.22L16.02,3.6L14.6,5.01l-4.25,4.23l0.01,0.01C10.14,9.43,10,9.7,10,10c0,0.55,0.45,1,1,1
	c0.3,0,0.56-0.14,0.75-0.35l0.01,0.01L15,7.43V23c0,0.55,0.45,1,1,1s1-0.45,1-1V7.42l3.24,3.26l0.02-0.02C20.44,10.86,20.7,11,21,11
	c0.55,0,1-0.45,1-1C22,9.69,21.85,9.42,21.63,9.24z"
      />
    </svg>
  )
}

export default ShareIcon
