import React from "react"

const LinkedInLogo = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 56 56">
      <path
        d="M28,0C12.54,0,0,12.54,0,28s12.54,28,28,28s28-12.54,28-28S43.46,0,28,0z M23.33,37.33h-4.67v-14h4.67V37.33z M21,21.25
	c-1.42,0-2.57-1.16-2.57-2.59c0-1.43,1.15-2.59,2.57-2.59s2.57,1.16,2.57,2.59C23.57,20.1,22.42,21.25,21,21.25z M39.67,37.33H35
	v-6.68c0-4.39-4.67-4.02-4.67,0v6.68h-4.67v-14h4.67v2.55c2.03-3.77,9.33-4.05,9.33,3.61V37.33z"
      />
    </svg>
  )
}

export default LinkedInLogo
