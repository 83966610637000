import React from "react"

const LinkedInShareIcon = () => {
  return (
    <svg viewBox="0 0 67 67" focusable="false">
      <path
        d="M49.8,48.1V36.4c0-6.3-3.3-9.2-7.8-9.2c-3.6,0-5.2,2-6.1,3.4v-2.9h-6.8c0.1,1.9,0,20.4,0,20.4h6.8V36.7
	c0-0.6,0-1.2,0.2-1.7c0.5-1.2,1.6-2.5,3.5-2.5c2.5,0,3.4,1.9,3.4,4.6v10.9L49.8,48.1L49.8,48.1z M22,24.9c2.4,0,3.8-1.6,3.8-3.5
	c0-2-1.5-3.5-3.8-3.5s-3.8,1.5-3.8,3.5C18.2,23.4,19.6,24.9,22,24.9L22,24.9z M25.4,48.1V27.7h-6.8v20.4H25.4z M3,4h60v60H3V4z"
      />
    </svg>
  )
}

export default LinkedInShareIcon
